import { object, string } from 'yup';

export const loginSchema = object({
  email: string()
    .required(() => 'errors.required-email')
    .email(() => 'errors.invalid-email'),
  password: string().required(() => 'errors.required-password')
});

export default loginSchema;
