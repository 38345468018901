
import { defineComponent, reactive, markRaw } from 'vue';
import { useStore, mapGetters, mapActions } from 'vuex';
import { useField, useForm } from 'vee-validate';
import validationSchema from '@/validator/login';
import { useRouter } from 'vue-router';
import VizButton from '@/components/ui/VizButton.vue';
import { logo } from '@/utils/icons';
import { userRoles } from '@/constants/role';
import { UserGroup, RoleContractFunctions } from '@/store/modules/auth';

interface ErrorList {
  error?: string;
}

interface ApiErrorInterface {
  errors: Array<ErrorList>;
  status: number;
}

export default defineComponent({
  name: 'Login',
  components: {
    VizButton
  },
  mounted() {
    const { email, error } = this.$route.params;
    if (email) {
      this.setFieldValue('email', String(email));
    }
    if (error) {
      this.state.errorMessage = error as string;
    }
  },
  setup() {
    const icons = markRaw({
      logo: logo
    });

    const state = reactive({
      errorMessage: '',
      isLoading: false
    });

    const { errors, handleSubmit, setFieldValue, meta } = useForm({
      validationSchema
    });

    const store = useStore();
    const router = useRouter();

    const getLandingPage = (userFunctions: Array<string>) => {
      let landingPage = 'AccountSettings';
      if (userFunctions) {
        if (userFunctions.includes('target_extraction')) {
          landingPage = 'Extraction';
        }

        if (userFunctions.includes('measure_simulation')) {
          landingPage = 'PatternSelection';
        }

        if (userFunctions.includes('contribution_analysis')) {
          landingPage = 'ContributionEnterData';
        }
      }
      return landingPage;
    };

    const handleLogin = handleSubmit(values => {
      const { email, password } = values;
      state.isLoading = true;
      store
        .dispatch('login', { email, password })
        .then(res => {
          const { group_status, user_role } = res?.data;
          state.isLoading = false;
          state.errorMessage = '';

          if (user_role == userRoles.MANAGER && group_status == false) {
            const data = {
              user_role,
              user_functions: []
            };
            store.commit('setUser', data);
            return router.push('/add-group');
          }

          store
            .dispatch('fetchUserGroups')
            .then((groups: Array<UserGroup>) => {
              const currentGroup = groups[0];
              if (currentGroup.id) {
                store
                  .dispatch('changeUserGroup', currentGroup)
                  .then((user: RoleContractFunctions) => {
                    router.push({
                      name: getLandingPage(user?.contract_functions)
                    });
                  });
              }
            })
            .catch(err => {
              if (err?.errors) {
                state.errorMessage = err?.errors ? err.errors[0].error : '';
              }
            });
        })
        .catch(err => {
          state.isLoading = false;

          if (err?.errors) {
            state.errorMessage = err.errors ? err.errors[0].error : '';
          }
        });
    });

    const { value: email } = useField('email');
    const { value: password } = useField('password');

    return {
      handleLogin,
      email,
      meta,
      password,
      errors,
      state,
      icons,
      setFieldValue
    };
  },
  methods: {
    ...mapActions(['resetLoginState'])
  },
  computed: {
    ...mapGetters(['isLoggedIn'])
  }
});
